import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import '../styles/Contact.css';
import Mapas from '../components/Mapas';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_296ktnf', 'template_uyi153j', form.current, 'W4hM68N2Jh_GYdp4w')
      .then(
        (result) => {
          alert('Mensaje enviado con éxito!');
          form.current.reset(); // Limpia el formulario
        },
        (error) => {
          alert(
            'Hubo un error al enviar el mensaje. Por favor, intenta nuevamente.'
          );
        }
      );
  };

  return (
    <>
      <div className="contact-container">
        <h1 className="pt-4 text-3xl text-center font-bold sm:text-7xl font-bignoodle">
          Contacto
        </h1>
        <div className="contact-header">
          {/* <p>Estamos aquí para ayudarte. Completa el formulario a continuación o usa nuestros detalles de contacto.</p> */}
        </div>
        <div className="contact-content">
          <div className="contact-form">
            <h2>Realizá tus consultas mediante el formulario.</h2>
            <form ref={form} onSubmit={sendEmail}>
              <div className="form-group">
                <label htmlFor="name">Nombre:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                  placeholder="Nombre"
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Teléfono:</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  required
                  placeholder="Teléfono"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Correo Electrónico:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  placeholder="Correo Electrónico"
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Mensaje:</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  required
                  placeholder="Mensaje"
                ></textarea>
              </div>
              <button type="submit" className="submit-button">
                Enviar
              </button>
            </form>
          </div>

          <div className="contact-info">
            <h2>Información de Contacto</h2>
            <p>
              <strong>Dirección:</strong> Monnteros, Shell RN38, T4142 Monteros, Tucumán
            </p>
            <p>
              <strong>Teléfono:</strong> (3863) 431975, SOLO LLAMADAS
              TELEFÓNICAS
            </p>
            <p>
              <strong>Correo Electrónico:</strong> shellshopmont@gmail.com
            </p>
          </div>
        </div>
      </div>
      <Mapas />
    </>
  );
}

export default Contact;
