import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import CafeImg from '../assets/products/IMG_4240.webp'
AOS.init();

const Us = () => {
  return (
    <div className="bg-gray-dark py-16">
      <div className="max-w-5xl mx-auto text-center px-8" data-aos="fade-up">
        <h1 className="text-5xl font-messina font-extrabold text-black mb-6">
          Bienvenido a <span className="text-gray-800">SHELL SHOP</span>
        </h1>
        <p className="text-lg text-gray-800 leading-relaxed mb-8">
          En <strong>SHELL SHOP MONTEROS</strong>, cada visita es una
          experiencia única. Disfruta de un café excepcional en un ambiente
          acogedor, donde cada detalle cuenta.
          <br />
          <br />
          Nuestro compromiso es ofrecerte productos de alta calidad y un
          servicio cálido y profesional. Ven y relájate en el mejor lugar para
          un buen café.
          <br />
          <br />
          <strong className="text-xl">
            ¡Visítanos y viví la diferencia en cada taza!
          </strong>
        </p>
        {/* Añade una imagen llamativa aquí */}
        <img
          src={CafeImg}
          alt="Imagen representativa"
          className="rounded-lg shadow-lg w-60 max-w-md mx-auto "
        />
        {/* Botón de llamada a la acción */}
        <button
          onClick={() => {
            const section = document.getElementById('about');
            section.scrollIntoView({ behavior: 'smooth' });
          }}
          className="bg-yellow hover:bg-yellow-600 transition-colors duration-300 px-8 py-3 rounded-full text-gray-900 font-semibold mt-6"
        >
          Descubre más
        </button>
      </div>
    </div>
  );
};

export default Us;
