import React from 'react';
import ProductCard from '../custom/ProductCard';
import cafe from '../assets/products/IMG_4227.webp';
import cafe2 from '../assets/products/IMG_4240.webp';
import cafe3 from '../assets/products/IMG_4222.webp';
import cafe4 from '../assets/products/IMG_4249.webp';
import cafe5 from '../assets/products/IMG_4254.webp';
import cafe6 from '../assets/products/IMG_4233.webp';
import '../styles/benefits.css';

import AOS from 'aos';
import 'aos/dist/aos.css';


AOS.init();

const Products = () => {
  return (
    <div id="productos" className="py-16 bg-shellSilver">
      <div className="container mx-auto px-5">
        <h1
          className="text-center text-5xl font-bold text-gray-800"
          data-aos="fade-left"
        >
          ALGUNOS PRODUCTOS
        </h1>
        <p
          className="text-center text-lg text-gray-600 mt-4"
          data-aos="fade-left"
        >
          Descubre nuestra selección exclusiva de productos de alta calidad.
        </p>

        {/* Primera fila de productos */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 pt-10">
          <div
            className="col-span-1 flex justify-center img-hover-zoom"
            data-aos="zoom-in"
          >
            <ProductCard
              image={cafe}
              title="Café con Leche"
              description="Que estás esperando para venir a disfrutar del mejor café con leche."
            />
          </div>
          <div
            className="col-span-1 flex justify-center img-hover-zoom"
            data-aos="zoom-in"
          >
            <ProductCard
              image={cafe2}
              title="Café y Torta Rogel"
              description="Disfruta de una experiencia única con nuestro café y la deliciosa torta Rogel, elaborada con los mejores ingredientes."
            />
          </div>
          <div
            className="col-span-1 flex justify-center img-hover-zoom"
            data-aos="zoom-in"
          >
            <ProductCard
              image={cafe3}
              title="Café Tostadas Jamón y Queso"
              description="Ideal para acompañar con nuestro café fuerte, disfruta de tostadas crujientes con jamón y queso que realzan cada sorbo."
            />
          </div>
        </div>

        {/* Segunda fila de productos */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 pt-10">
          <div
            className="col-span-1 flex justify-center img-hover-zoom"
            data-aos="zoom-in"
          >
            <ProductCard
              image={cafe4}
              title="Café y Medialunas"
              description="Disfruta del clásico sabor de nuestras medialunas recién horneadas, perfectas para acompañar con un buen café. Ideal para un desayuno o merienda irresistible."
            />
          </div>
          <div
            className="col-span-1 flex justify-center img-hover-zoom"
            data-aos="zoom-in"
          >
            <ProductCard
              image={cafe5}
              title="Café y Cosas Dulces"
              description="Un deleite para quienes disfrutan del café recién molido acompañado de una variedad de dulces irresistibles. La combinación perfecta para endulzar tu día."

            />
          </div>
          <div
            className="col-span-1 flex justify-center img-hover-zoom"
            data-aos="zoom-in"
          >
            <ProductCard
              image={cafe6}
              title="Café Clasico"
              description="Disfruta de una combinación clásica y deliciosa con tostadas crujientes, yogurt fresco y una ensalada de frutas vibrante. La merienda ideal para recargar energías y saborear momentos especiales."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
