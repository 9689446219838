import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function Mapas() {
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.state]);

  return (
    <div>
      <div
        className="flex flex-col md:flex-row lg:flex-row justify-center items-center mx-auto w-full transition-opacity duration-500"
        data-aos="fade-up"
        id="mapas"
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3549.4892812142584!2d-65.49325652375168!3d-27.172354776493393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9423cb8b8f48135d%3A0xa0668924e4b432b3!2sShell!5e0!3m2!1ses-419!2sar!4v1722990818510!5m2!1ses-419!2sar"
          width="100%"
          height="450"
          style={{ border: '0' }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Ubicación de Shell en Google Maps"
        ></iframe>
      </div>
    </div>
  );
}
