import Sanguche1 from '../../src/assets/products/IMG_5218.webp';
import Sanguche2 from '../../src/assets/products/IMG_5214.webp';
import Sanguche3 from '../../src/assets/products/IMG_5223.webp';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AOS from 'aos';
import 'aos/dist/aos.css';


AOS.init();

const Sanguche = () => {
   const settings = {
     dots: true,
     infinite: true,
     speed: 500,
     slidesToShow: 1,
     slidesToScroll: 1,
     autoplay: true,
     autoplaySpeed: 3000,
     arrows: true,
     adaptiveHeight: true
   };
  return (
    <>
      <section
        id="about"
        className="overflow-hidden h-full bg-white dark:bg-gradient-to-r  relative"
      >
        <div className="container mx-auto mt-10">
          <div className="flex flex-wrap items-center justify-between lg:px-16 bgvsps">
            <div className="w-full px-4 lg:w-6/12 ">
              <div className="max-lg:flex-col flex items-center sm:-mx-4 justify-center max-lg:pt-20 lg:pt-0 ">
                <Slider {...settings} className="w-72">
                  <div>
                    <img
                      src={Sanguche1}
                      alt="Sanguche 1"
                      className="rounded-lg shadow-lg w-full h-auto"
                    />
                  </div>
                  <div>
                    <img
                      src={Sanguche2}
                      alt="Sanguche 2"
                      className="rounded-lg shadow-lg w-full h-auto"
                    />
                  </div>
                  <div>
                    <img
                      src={Sanguche3}
                      alt="Sanguche 3"
                      className="rounded-lg shadow-lg w-full h-auto"
                    />
                  </div>
                </Slider>
              </div>
            </div>

            <div
              data-aos="fade-left"
              className="my-20 w-contain max-md:px-14 md:pl-10 lg:w-1/2 xl:w-5/12"
            >
              <div className="mt-10 lg:mt-0 md:px-10 lg:px-5">
                {/* <span className="block mb-4 text-lg font-messina text-primary dark:text-orange-500">
                  ¿Porqué elegirnos?
                </span> */}
                <h2 className="mb-5 text-3xl font-bignoodle tracking-wide lg:text-[50px] font-bold sm:text-[40px]/[48px] dark:text-black">
                  VENÍ A PROBAR! NUESTRO SANGUCHE DE MILANESA
                </h2>
                <p className="mb-8 text-base text-body-color dark:text-black font-messina">
                  ¡Vení a disfrutar de nuestro famoso sanguche de milanesa! Un
                  manjar bien nuestro, hecho con ingredientes frescos y mucho
                  cariño. Es ese sabor de siempre que te hace sentir en casa.
                </p>

                <p className="mb-8 text-base text-body-color dark:text-black font-messina">
                  Vení a compartir con amigos, en un ambiente cálido y relajado.
                  ¡Te esperamos para que vivas la experiencia de un buen
                  sanguche de milanesa como sólo nosotros lo hacemos!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sanguche;
