import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import Us from '../components/us';
import Products from '../components/products';
import Benefits from '../components/benefits';
import Sanguche from '../components/Sanguche';
import Pizza from '../components/Pizza';

const Home = () => {
  const [showSanguche, setShowSanguche] = useState(true);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setShowSanguche((prev) => !prev);
        setFade(true);
      }, 500); // Duración del desvanecimiento
    }, 5000); // Cambiar cada 4 segundos

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Header />
      <Us />
      <Benefits />
      <div
        className={`transition-opacity duration-500 ${
          fade ? 'opacity-100' : 'opacity-0'
        }`}
      >
        {showSanguche ? <Sanguche /> : <Pizza />}
      </div>
      <Products />
    </div>
  );
};

export default Home;
